/** @jsx jsx */
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  Button,
  CapsBar,
  Carousel,
  Container,
  Heading,
  Rating,
  UserGeneratedHtml,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { Trans } from "@trueskin-web/translations"

import Blocks from "../block-library/components/blocks"
import SEODetails from "../block-library/components/seo-details"
import Layout from "../components/layout"

const ReviewsPage = ({
  data: {
    strapi: { reviewsPage },
  },
}) => (
  <Layout title={reviewsPage.title}>
    <SEODetails title={reviewsPage.title} {...reviewsPage.seoDetails} />
    {reviewsPage.blocks?.length ? (
      <Blocks blocks={reviewsPage.blocks} />
    ) : (
      <Container>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["auto", "1fr 2fr"],
            gap: [null, 7],
            mt: [null, 9],
            mb: [null, 10],
          }}
        >
          <div sx={{ mt: [7, 0], mb: [9, 0] }}>
            <Heading as="h1" size="lg" mb={5}>
              {reviewsPage.heading}
            </Heading>

            <CapsBar>
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {reviewsPage.reviewerTitle}:
                <div sx={{ display: "flex", alignItems: "center" }}>
                  <Rating rating={reviewsPage.rating} size="sm" />
                  <div sx={{ ml: 2 }}>{reviewsPage.rating}</div>
                </div>
              </div>
            </CapsBar>

            <CapsBar sx={{ mt: "-1px", mb: 4 }}>
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Trans i18nKey="Reviews.reviews" />
                </div>
                <div sx={{ fontFamily: "monospace" }}>
                  {reviewsPage.totalReviews}
                </div>
              </div>
            </CapsBar>

            <div sx={{ display: "flex", flexDirection: "column" }}>
              <Button
                size="lg"
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    href: reviewsPage.primaryCta.targetUrl,
                  }).click()
                }}
              >
                {reviewsPage.primaryCta.label}
              </Button>
            </div>
          </div>

          <div
            sx={{
              variant: "layout.sectionPadding",
              bg: ["grey", "transparent"],
              mx: [-4, 0],
              px: 4,
              p: [null, 0],
            }}
          >
            <Heading size="lg" mb={[5, 6]} sx={{ display: [null, "none"] }}>
              {reviewsPage.reviews.title}
            </Heading>

            <div
              sx={{
                display: "grid",
                gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
                gap: 7,
                columnGap: [null, 5],
              }}
            >
              {reviewsPage.reviews.reviews.map((review) => (
                <div key={review.author}>
                  <div sx={{ mb: [5, 6] }}>
                    <Carousel>
                      {review.images.map((image) => (
                        <GatsbyImage
                          image={
                            image.imageFile.childImageSharp.gatsbyImageData
                          }
                          alt=""
                          key={image.url}
                          sx={{
                            maxHeight: [400, "initial"],
                          }}
                        />
                      ))}
                    </Carousel>
                  </div>

                  <Heading as="h3" mb={2}>
                    {review.author}
                  </Heading>

                  <UserGeneratedHtml html={review.content} sx={{ mb: 5 }} />

                  <CapsBar>
                    <div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Trans i18nKey="Reviews.rating" />
                      <Rating rating={review.rating} size="sm" />
                    </div>
                  </CapsBar>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    )}
  </Layout>
)

export const query = graphql`
  {
    strapi {
      reviewsPage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        heading
        reviewerTitle
        rating
        totalReviews
        primaryCta {
          label
          targetUrl
        }
        reviews {
          title
          reviews {
            author
            images {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    aspectRatio: 1.6
                    layout: CONSTRAINED
                  )
                }
              }
            }
            content
            rating
          }
        }
        blocks {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksBannerUsp {
            ...BannerUspFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
        }
      }
    }
  }
`

export default ReviewsPage
